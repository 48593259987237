<script setup lang="ts">
import { PhCaretLeft, PhCaretRight } from '@phosphor-icons/vue';
import { IconButton } from '@laam/ui/base';
import type { DropsResponse } from '@laam/cms-shared';

interface DropsBar {
	drops: DropsResponse;
}
defineProps<DropsBar>();

const scrollContainerRef = ref<HTMLElement | null>(null);
const showLeftIcon = ref(false);
const showRightIcon = ref(true);

const checkScrollPosition = () => {
	if (scrollContainerRef.value) {
		const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.value;

		// Show left icon if we've scrolled at all
		showLeftIcon.value = scrollLeft > 0;

		// Show right icon if we haven't reached the end
		// Adding a small buffer (1px) to account for rounding errors
		showRightIcon.value = Math.abs(scrollWidth - clientWidth - scrollLeft) > 1;
	}
};

const handleScroll = (goRight: boolean) => {
	if (scrollContainerRef.value) {
		if (goRight) {
			scrollContainerRef.value.scrollBy({
				left: 200,
				behavior: 'smooth',
			});
		} else {
			scrollContainerRef.value.scrollBy({
				left: -200,
				behavior: 'smooth',
			});
		}
	}
};

// Watch for scroll events
onMounted(() => {
	scrollContainerRef.value?.addEventListener('scroll', checkScrollPosition);
	// Check initial scroll position
	checkScrollPosition();
});

onBeforeUnmount(() => {
	scrollContainerRef.value?.removeEventListener('scroll', checkScrollPosition);
});
</script>
<template>
	<div class="flex relative items-center justify-between">
		<IconButton
			v-if="showLeftIcon && !isMobileView()"
			rounded="full"
			variant="secondary"
			size="xs"
			class="top-[30px] absolute z-10 left-[-17px]"
			@click="handleScroll(false)"
		>
			<PhCaretLeft />
		</IconButton>
		<div
			ref="scrollContainerRef"
			class="w-full lg:px-none h-fit px-3xl relative overflow-x-scroll no-scrollbar"
		>
			<div class="flex flex-start gap-md w-full min-w-max">
				<!-- New Bar -->
				<div v-for="(item, idx) in drops" :key="idx">
					<NuxtLink
						:to="`/drops/${item.handle}`"
						class="cursor-pointer relative text-center text-xs font-medium text-gray-800"
					>
						<div class="px-lg py-md bg-gray-100 rounded-xs">
							{{ item.title }}
						</div>
					</NuxtLink>
				</div>
			</div>
		</div>
		<IconButton
			v-if="showRightIcon && !isMobileView()"
			rounded="full"
			variant="secondary"
			size="xs"
			class="top-[30px] absolute z-10 right-[-17px]"
			@click="handleScroll(true)"
		>
			<PhCaretRight />
		</IconButton>
	</div>
</template>
